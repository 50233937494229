import { IRootMenuItem } from './Header.interfaces';

const PUBLIC_MENU: IRootMenuItem[] = [
  {
    id: 1,
    href: '/',
    label: 'About',
    subMenuItems: [
      {
        id: 1,
        description: 'Who are we?',
        href: '/about-us',
        label: 'About us',
      },
      {
        id: 2,
        description: 'Our Research and Caregiver Stories',
        href: '/our-research',
        label: 'Research & stories',
      },
    ],
  },
  {
    id: 2,
    href: '/resources',
    label: 'Resources',
  },
  {
    id: 3,
    href: '/directory',
    label: 'Directory',
  },
  {
    id: 4,
    href: '/register',
    label: 'Friend and Family Support Program Portal',
    privateHref: '/dashboard',
  },
];

const PRIVATE_MENU: IRootMenuItem[] = [
  {
    id: 1,
    href: '/dashboard',
    label: 'Dashboard',
  },
  {
    id: 2,
    href: '/toolbox',
    label: 'Toolbox',
  },
  {
    id: 3,
    href: '/resources',
    label: 'Resources',
  },
];

export { PUBLIC_MENU, PRIVATE_MENU };
