import { useEffect, useState } from 'react';

import { useToggle } from '@netfront/common-library';
import { useDomain, useLogout } from '@netfront/gelada-identity-library';
import { useRouter } from 'next/router';

import { PRIVATE_MENU } from '../Header.constants';
import { PreHeader } from '../PreHeader';

import { useToast } from '../../../hooks/useToast';
import { Button } from '../../Button';
import { Link } from '../../Link';
import { LogoLink } from '../../LogoLink';
import { MenuDropdown } from '../../MenuDropdown';
import { MobileMenuTrigger } from '../../Menus';
import { PrivateMobileMenu } from '../../Menus/PrivateMobileMenu';
import { UserInitials } from '../../UserInitials';

const PrivateHeader = () => {
  const { push } = useRouter();
  const { handleToastError } = useToast();

  const { isToggled: isMobileMenuOpen, toggle: toggleMobileMenu } = useToggle();
  const { isToggled: isUserMenuOpen, toggle: toggleUserMenu } = useToggle();

  const { getDomain, isDomainReady } = useDomain();
  const [domain, setDomain] = useState<string>('');

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }
    setDomain(getDomain());
  }, [isDomainReady]);

  const { handleLogout } = useLogout({
    onCompleted: () => {
      push('/').catch((error) =>
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        }),
      );
    },
  });

  return (
    <header className="index-2 relative shadow no-print">
      <PreHeader isDisplay={!isMobileMenuOpen} />

      <div className="bg-white border-b border-grey-100 index-2 py-4 relative xl:py-0">
        <div className="align-center container-xl flex">
          <LogoLink />

          <MobileMenuTrigger isOpen={isMobileMenuOpen} onPress={toggleMobileMenu} />

          <nav className="ml-auto none xl:flex -mr-12">
            <ul className="align-center flex">
              {PRIVATE_MENU.map(({ href, id, label }) => {
                const buttonClasses = 'block color-black font-header hover:bg-grey-50 pointer px-12 py-8 weight-700';

                return (
                  <li key={id}>
                    <Link className={buttonClasses} href={href}>
                      {label}
                    </Link>
                  </li>
                );
              })}
              <li className="ml-4 relative">
                <Button aria-expanded={isUserMenuOpen} className="pointer p-0" aria-haspopup onPress={toggleUserMenu}>
                  <UserInitials />
                </Button>

                {isUserMenuOpen && (
                  <MenuDropdown
                    className="absolute bg-white border border-grey-100 overflow-hidden radius right-0 shadow-lg w-auto"
                    onOpenChange={toggleUserMenu}
                  >
                    <ul className="m-0">
                      <li className="m-0">
                        <Button className="hover:bg-grey-50 pointer p-4 px-8" onPress={() => handleLogout({ domain })}>
                          Logout
                        </Button>
                      </li>
                    </ul>
                  </MenuDropdown>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </div>
      {isMobileMenuOpen && <PrivateMobileMenu />}
    </header>
  );
};

export { PrivateHeader };
