import { ButtonHTMLAttributes, useRef } from 'react';

import { ButtonAria, useButton } from '@react-aria/button';
import cx from 'classnames';

import { BUTTON_CLASSES } from './Button.constants';
import { ButtonProps } from './Button.interfaces';

const Button = (props: ButtonProps) => {
  const { children, className = '', onMouseEnter, onMouseLeave, theme } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);

  const { buttonProps } = useButton(props, buttonRef) as ButtonAria<ButtonHTMLAttributes<HTMLButtonElement>>;

  const buttonTheme = theme ? BUTTON_CLASSES[theme] : '';

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <button
      type="button"
      {...buttonProps}
      ref={buttonRef}
      className={cx(buttonTheme, className)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </button>
  );
};

export { Button };
