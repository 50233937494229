import { useState } from 'react';

import { useToggle } from '@netfront/common-library';
import { useAuthentication } from '@netfront/gelada-identity-library';
import cx from 'classnames';

import { PUBLIC_MENU } from '../Header.constants';
import { IRootMenuItem } from '../Header.interfaces';
import { PreHeader } from '../PreHeader';

import { Button } from '../../Button';
import { CaretDownIcon } from '../../Icons';
import { Link } from '../../Link';
import { LogoLink } from '../../LogoLink';
import { MenuDropdown } from '../../MenuDropdown';
import { MobileMenuTrigger, PublicMobileMenu } from '../../Menus';

const PublicHeader = () => {
  const [isDropDownMenuOpen, setIsDropDownMenuOpen] = useState<boolean>(false);
  const [selectedDropDown, setSelectedDropDown] = useState<IRootMenuItem>();

  const { isToggled: isMobileMenuOpen, toggle } = useToggle();

  const { isAuthenticated } = useAuthentication();

  const handleMenuClose = () => {
    setIsDropDownMenuOpen(false);
    setSelectedDropDown(undefined);
  };

  const handleMenuPress = (item: IRootMenuItem) => {
    if (item === selectedDropDown && isDropDownMenuOpen) {
      handleMenuClose();
      return;
    }

    setIsDropDownMenuOpen(true);
    setSelectedDropDown(item);
  };

  return (
    <header className="index-2 relative shadow flex flex-col">
      <PreHeader isDisplay={!isMobileMenuOpen} />

      <div className="bg-white border-b border-grey-100 index-2 py-4 relative xl:py-0">
        <div className="align-center container-xl flex">
          <LogoLink />

          <MobileMenuTrigger isOpen={isMobileMenuOpen} onPress={toggle} />

          <nav className="ml-auto none xl:flex -mr-12">
            <ul className="align-center flex">
              {PUBLIC_MENU.map((item) => {
                const buttonClasses = 'align-center block color-black flex font-header hover:bg-grey-50 pointer px-12 py-8 weight-700';

                const { href, id, label, privateHref, subMenuItems = [] } = item;

                const itemHref = isAuthenticated && privateHref ? privateHref : href;

                return (
                  <li key={id}>
                    {subMenuItems.length ? (
                      <Button
                        aria-expanded={isDropDownMenuOpen}
                        className={buttonClasses}
                        aria-haspopup
                        onPress={() => handleMenuPress(item)}
                      >
                        {label}
                        <div
                          className={cx('align-center', 'bg-grey-50', 'flex', 'justify-center', 'ml-2', 'radius-full', 'transition-200', {
                            'rotate-180': id === selectedDropDown?.id,
                          })}
                        >
                          <CaretDownIcon />
                        </div>
                      </Button>
                    ) : (
                      <Link className={buttonClasses} href={itemHref}>
                        {label}
                      </Link>
                    )}
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </div>

      {isMobileMenuOpen && <PublicMobileMenu />}

      {isDropDownMenuOpen && selectedDropDown && (
        <MenuDropdown className="none xl:flex top-full" onOpenChange={handleMenuClose}>
          <div className="container-md py-12">
            <ul className="flex m-0">
              {selectedDropDown.subMenuItems?.map(({ description, href, id, label }) => (
                <li key={id} className="flex flex-col mr-24">
                  <span className="font-header weight-800 h6 mb-6">{label}</span>
                  <Link className="color-accent font-header text-underline weight-700" href={href}>
                    {description}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </MenuDropdown>
      )}
    </header>
  );
};

export { PublicHeader };
