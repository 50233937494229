import { HelpIcon } from '../Icons';
import { Link } from '../Link';

import { HelpButtonProps } from './HelpButton.interfaces';

const HelpButton = ({ className }: HelpButtonProps) => (
  <Link className={className} href="/get-help-now" theme="red">
    <div className="flex mr-2">
      <HelpIcon />
    </div>
    Get help now
  </Link>
);

export { HelpButton };
