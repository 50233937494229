import { useEffect, useState } from 'react';

import { useDomain, useLogout } from '@netfront/gelada-identity-library';
import { usePreventScroll } from '@react-aria/overlays';
import { useRouter } from 'next/router';

import { useToast } from '../../../hooks/useToast';
import { Button } from '../../Button';
import { PRIVATE_MENU } from '../../Header';
import { Link } from '../../Link';
import { MenuDropdown } from '../../MenuDropdown';

const PrivateMobileMenu = () => {
  usePreventScroll();

  const { push } = useRouter();
  const { handleToastError } = useToast();

  const { getDomain, isDomainReady } = useDomain();
  const [domain, setDomain] = useState<string>('');

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }
    setDomain(getDomain());
  }, [isDomainReady]);

  const { handleLogout } = useLogout({
    onCompleted: () => {
      push('/').catch((error) =>
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        }),
      );
    },
  });

  return (
    <MenuDropdown className="flex flex-col h-screen overflow-y-auto pt-20 top-0 xl:none">
      <div className="flex flex-1 flex-col pb-8">
        <nav>
          <ul className="flex flex-col m-0">
            {PRIVATE_MENU.map((item) => {
              const buttonClasses = 'block color-black font-header hover:bg-grey-50 pointer px-6 py-6 weight-700';

              const { href, id, label } = item;

              return (
                <li key={id}>
                  <Link className={buttonClasses} href={href}>
                    {label}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>

        <div className="align-center flex flex-col justify-center mb-16 mt-auto">
          <div className="mb-8">
            <Button theme="red" onPress={() => handleLogout({ domain })}>
              Logout
            </Button>
          </div>
          <div className="text-center">
            <span className="color-black">&copy; {new Date().getFullYear()} CarerWell</span>
          </div>
        </div>
      </div>
    </MenuDropdown>
  );
};

export { PrivateMobileMenu };
