import { ButtonClasses, ButtonSize, ButtonTheme } from './Button.types';

const BASE_STYLES = 'align-center font-header inline-flex justify-center text-center pointer px-4 radius weight-700';

const BUTTON_FOCUS_CLASSES: Record<ButtonTheme, string> = Object.freeze({
  blue: 'focus:bg-blue-800 focus:border-white focus:color-white focus:ring-blue-800',
  default: 'focus:bg-accent focus:border-white focus:color-white focus:ring-accent',
  red: 'focus:bg-red-900 focus:border-white focus:color-white focus:ring-red-900',
});

const BUTTON_SIZE_CLASSES: Record<ButtonSize, string> = Object.freeze({
  auto: 'h-auto min-w-auto',
  default: 'h-13 min-w-40',
  lg: '',
  md: '',
  sm: '',
  xl: '',
});

const BUTTON_CLASSES: ButtonClasses = Object.freeze({
  blue: `${BASE_STYLES} bg-white border-2 border-blue-800 color-blue-800 hover:bg-blue-800 hover:color-white ${BUTTON_SIZE_CLASSES.default} ${BUTTON_FOCUS_CLASSES.blue}`,
  default: `${BASE_STYLES} bg-white border-2 border-accent color-accent hover:bg-accent hover:color-white ${BUTTON_SIZE_CLASSES.default} ${BUTTON_FOCUS_CLASSES.default}`,
  red: `${BASE_STYLES} bg-red-800 border-2 border-red-800 color-white hover:bg-red-900 ${BUTTON_FOCUS_CLASSES.red} ${BUTTON_SIZE_CLASSES.default}`,
});

export { BASE_STYLES, BUTTON_CLASSES, BUTTON_FOCUS_CLASSES, BUTTON_SIZE_CLASSES };
