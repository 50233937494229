import { FOOTER_MENU_ITEMS } from '../Footer.constants';

import { Link } from '../../Link';

const PrivateFooter = () => {
  return (
    <footer className="mt-auto no-print">
      <div className="bg-white">
        <div className="container-xl flex flex-col-reverse md:align-center xl:flex-row">
          <div className="mb-6 xl:mb-0">
            <span>&copy; {new Date().getFullYear()} CarerWell</span>
          </div>
          <span className="align-center mb-0 flex xl:none">
            Built by
            <Link className="block ml-1 color-accent text-underline" href="https://netfront.com.au/" target="_blank">
              Netfront
            </Link>
          </span>

          <nav className="flex flex-1 md:justify-end py-6 xl:justify-center">
            <ul className="flex flex-col md:flex-row m-0">
              {FOOTER_MENU_ITEMS.map(({ href, id, label }) => (
                <li key={id} className="color-accent font-header text-underline weight-700">
                  <Link key={id} className="block font-header color-accent mb-6 md:mb-0 md:mx-8 text-underline weight-700" href={href}>
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          <span className="align-center mb-0 none xl:flex">
            Built by
            <Link className="block ml-1 color-accent text-underline" href="https://netfront.com.au/" target="_blank">
              Netfront
            </Link>
          </span>
        </div>
      </div>
    </footer>
  );
};

export { PrivateFooter };
