import { Link } from '../Link';

const LogoLink = () => {
  return (
    <Link className="inline-block" href="/">
      <span className="sr-only">Go to homepage</span>
      <img alt="Go to homepage" className="block md:none" loading="lazy" src="/images/carerwell-logo-icon.svg" />
      <img alt="Go to homepage" className="md:block none" loading="lazy" src="/images/carerwell-logo.svg" />
    </Link>
  );
};

export { LogoLink };
