import { IMenuItem } from '../../interfaces';

export const FOOTER_MENU_ITEMS: IMenuItem[] = [
  {
    id: 1,
    href: '/contact',
    label: 'Contact us',
  },
  {
    id: 2,
    href: '/privacy-statement',
    label: 'Privacy policy',
  },
  {
    id: 3,
    href: '/terms-and-conditions',
    label: 'Terms & conditions',
  },
];
