import cx from 'classnames';

import { FOOTER_MENU_ITEMS } from '../Footer.constants';

import { HelpButton } from '../../HelpButton';
import { Link } from '../../Link';

const PublicFooter = () => {
  return (
    <footer className="mt-auto">
      <div className="bg-white md:py-24 py-16">
        <div className="container-md flex flex-col md:flex-row">
          <div className="mb-8 md:mb-0 md:w-half w-full">
            <div className="align-center flex mb-12">
              <img alt="Indigenous flag" className="mr-2 w-12" loading="lazy" src="/images/australian-aboriginal-flag.png" />
              <img alt="Torres Strait Islander flag" className="mr-2 w-12" loading="lazy" src="/images/torres-strait-islander-flag.png" />
              <img alt="LGBT flag" className="mr-2 w-12" loading="lazy" src="/images/lgbt-flag.png" />
              <img alt="Transgender flag" className="mr-2 w-12" loading="lazy" src="/images/transgender-flag.png" />
            </div>
            <p>
              CarerWell acknowledges the Traditional Owners of the lands we are on and pays respect to their Elders past and present.
              CarerWell recognises and respects the cultural heritage, beliefs, and ongoing connection to Country, which continue to be
              important to the First Nations people today.
            </p>
            <p>
              The CarerWell team are committed to embracing diversity and challenging all forms of discrimination in health and social care.
            </p>
          </div>
          <div className="flex flex-col justify-center md:align-center md:w-half w-full">
            <nav className="mb-8">
              <ul className="m-0">
                {FOOTER_MENU_ITEMS.map(({ href, id, label }, index) => (
                  <li key={id} className="color-accent font-header text-underline weight-700">
                    <Link
                      className={cx('block', 'color-accent', 'font-header', 'text-underline', 'weight-700', {
                        'mb-6': index < FOOTER_MENU_ITEMS.length,
                      })}
                      href={href}
                    >
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
            <HelpButton />
          </div>
        </div>
      </div>

      <div className="bg-blue-800">
        <div className="container-lg py-4 flex align-center flex-col md:flex-row kanzi-ignore">
          <span className="color-white md:mb-0 flex-1">&copy; {new Date().getFullYear()} CarerWell</span>
          <span className="color-white align-center mb-0 flex">
            Built by
            <Link className="color-white block ml-1 text-underline" href="https://netfront.com.au/" target="_blank">
              Netfront
            </Link>
          </span>
        </div>
      </div>
    </footer>
  );
};

export { PublicFooter };
