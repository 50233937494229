import { usePerson } from '@netfront/gelada-identity-library';
import { useEffect, useState } from 'react';

const UserInitials = () => {
  const { initials } = usePerson();

  const [isHydrated, setIsHydrated] = useState<boolean>(false);

  useEffect(() => {
    setIsHydrated(true);
  }, []);

  if (!isHydrated) {
    return null;
  }

  return (
    <div className="bg-red-800 radius h-12 w-12 flex align-center justify-center">
      <span className="size-3 bg-white font-header flex align-center justify-center color-red-800 radius-circle weight-800 h-8 w-8 m-0">
        {initials}
      </span>
    </div>
  );
};

export { UserInitials };
