import { useEffect, useState } from 'react';

import { useAuthentication, useDomain, useLogout } from '@netfront/gelada-identity-library';
import { usePreventScroll } from '@react-aria/overlays';
import cx from 'classnames';
import { useRouter } from 'next/router';

import { useToast } from '../../../hooks/useToast';
import { Button } from '../../Button';
import { PUBLIC_MENU, IRootMenuItem } from '../../Header';
import { CaretDownIcon } from '../../Icons';
import { Link } from '../../Link';
import { MenuDropdown } from '../../MenuDropdown';

const PublicMobileMenu = () => {
  usePreventScroll();

  const { isAuthenticated } = useAuthentication();
  const { push } = useRouter();
  const { handleToastError } = useToast();

  const [selectedDropDown, setSelectedDropDown] = useState<IRootMenuItem | null>(null);

  const { getDomain, isDomainReady } = useDomain();
  const [domain, setDomain] = useState<string>('');

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }
    setDomain(getDomain());
  }, [isDomainReady]);

  const handleMenuPress = (item: IRootMenuItem) => {
    setSelectedDropDown(item.id === selectedDropDown?.id ? null : item);
  };

  const { handleLogout } = useLogout({
    onCompleted: () => {
      push('/').catch((error) =>
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        }),
      );
    },
  });

  return (
    <MenuDropdown className="flex flex-col h-screen overflow-y-auto pt-20 top-0 xl:none">
      <div className="flex flex-1 flex-col pb-8">
        <nav>
          <ul className="flex flex-col m-0">
            {PUBLIC_MENU.map((item) => {
              const buttonClasses = 'block color-black font-header hover:bg-grey-50 pointer px-6 py-6 weight-700';

              const { href, id, label, privateHref, subMenuItems = [] } = item;

              const itemHref = isAuthenticated && privateHref ? privateHref : href;

              const isActiveItem = id === selectedDropDown?.id;

              return (
                <li key={id}>
                  {subMenuItems.length ? (
                    <>
                      <Button
                        aria-expanded={isActiveItem}
                        className={cx('align-center', 'flex', 'w-full', buttonClasses)}
                        aria-haspopup
                        onPress={() => handleMenuPress(item)}
                      >
                        {label}
                        <div
                          className={cx(
                            'align-center',
                            'bg-grey-50',
                            'flex',
                            'justify-center',
                            'ml-auto',
                            'radius-full',
                            'transition-200',
                            {
                              'rotate-180': isActiveItem,
                            },
                          )}
                        >
                          <CaretDownIcon />
                        </div>
                      </Button>
                      {isActiveItem && (
                        <ul className="bg-grey-100 flex flex-col pb-6 pt-10 px-6 m-0">
                          {subMenuItems.map(({ description, href: subMenuHref, id: subMenuId, label: subMenuLabel }) => (
                            <li key={subMenuId} className="flex flex-col mb-12">
                              <span className="font-header h6 mb-6 weight-800">{subMenuLabel}</span>
                              <Link className="color-accent font-header text-underline weight-700" href={subMenuHref}>
                                {description}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </>
                  ) : (
                    <Link className={buttonClasses} href={itemHref}>
                      {label}
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        </nav>

        <div className="align-center flex justify-center mb-8 mt-auto">
          {!isAuthenticated ? (
            <>
              <Link className="mx-2" href="/login" theme="default">
                Login
              </Link>
              <Link className="mx-2" href="/register" theme="blue">
                Register
              </Link>
            </>
          ) : (
            <Button theme="red" onPress={() => handleLogout({ domain })}>
              Logout
            </Button>
          )}
        </div>

        <div className="text-center mb-16">
          <span className="color-black">&copy; {new Date().getFullYear()} CarerWell</span>
        </div>
      </div>
    </MenuDropdown>
  );
};

export { PublicMobileMenu };
